<template>
    <section class="hero relative flex w-full lg:min-h-screen">
        <div
            class="absolute inset-x-0 top-1/3 -z-10 flex pl-0 blur-2xl sm:top-auto md:top-1/2 md:-translate-y-1/3 lg:-bottom-40 lg:right-[calc(50%-6rem)]"
            aria-hidden="true"
        >
            <div
                class="aspect-[4/3] w-full flex-none border border-black bg-gradient-to-r from-[#80cdfa] to-[#e65454] opacity-25"
                style="
                clip-path: polygon(
                    74.1% 44.1%,
                    100% 61.6%,
                    97.5% 26.9%,
                    85.5% 0.1%,
                    80.7% 2%,
                    72.5% 32.5%,
                    60.2% 62.4%,
                    52.4% 68.1%,
                    47.5% 58.3%,
                    45.2% 34.5%,
                    27.5% 76.7%,
                    0.1% 64.9%,
                    17.9% 100%,
                    27.6% 76.8%,
                    76.1% 97.7%,
                    74.1% 44.1%
                );
                "
            />
        </div>
        <div class="mx-auto max-w-[1800px] px-8 md:px-12 lg:px-16 xl:flex">
            <div
                data-aos="fade-up"
                class="mx-auto mt-20 flex max-w-2xl flex-shrink-0 flex-col justify-center pt-8 text-center lg:pt-12 xl:mx-0 xl:mt-0 xl:max-w-2xl xl:pb-20 xl:text-start"
            >
                <h1
                    data-aos="fade-up"
                    class="text-[12vw] font-bold !leading-[110%] tracking-tighter text-gray-900 sm:text-5xl lg:text-6xl"
                >
                    <span>Plan sneller,</span>
                    <span
                        data-aos="fade-left"
                        data-aos-delay="600"
                        data-aos-duration="600"
                        class="block text-primary-500"
                    >
                        organiseer makkelijker
                    </span>
                </h1>
                <p class="my-2 mb-5 text-lg leading-8 text-gray-600 xl:mb-8">
                    Bepaal wie, waar en wanneer aanwezig moet zijn. Geef instructies, plan
                    uw evenement en neem de controle in handen.
                </p>
                <div
                    class="mt-10 flex flex-col items-center justify-center gap-x-6 space-y-5 sm:flex-row sm:space-y-0 xl:justify-start"
                >
                    <Button
                        href="/dashboard"
                        class="bg-primary-500 hover:bg-primary-500"
                    >
                        Begin nu
                        <ArrowRightIcon class="ml-3 h-4" aria-hidden="true" />
                    </Button>
                    <Button href="#features" class="bg-pink-600 hover:bg-pink-500">
                        Bekijk functionaliteiten
                        <ArrowDownIcon class="ml-2 h-4 animate-bounce" aria-hidden="true" />
                    </Button>
                </div>
            </div>

            <!-- Image -->
            <div
                class="mx-auto mb-12 mt-16 flex min-h-[200px] max-w-2xl items-center justify-center md:mb-16 xl:mb-0 xl:ml-10 xl:mr-0 xl:mt-0 xl:max-w-none xl:flex-none 2xl:ml-28"
            >
                <div
                    data-aos="fade-left"
                    class="max-w-3xl flex-none sm:max-w-5xl xl:flex xl:h-[65vh] xl:max-w-none"
                >
                    <img
                        :src="asset('assets/images/front-page.png')"
                        alt="App screenshot"
                        height="200"
                        class="w-screen translate-x-[5%] rounded-md bg-white object-contain object-left p-1 shadow-2xl shadow-gray-300 ring-1 ring-gray-300/50 transition-all duration-300 ease-in-out sm:translate-x-[10%] xl:w-[65vw] xl:translate-x-0 xl:object-cover 2xl:w-[64rem]"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { ArrowDownIcon, ArrowRightIcon } from "@heroicons/vue/24/outline";
import Button from "@/Components/Button.vue";
export default {
    components: {
        ArrowDownIcon,
        ArrowRightIcon,
        Button,
    },
};
</script>
