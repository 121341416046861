import './bootstrap';
import '../css/app.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'aos/dist/aos.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { createPinia } from 'pinia'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import * as Sentry from "@sentry/vue";
import Toast, { useToast } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

const pinia = createPinia();

const toast = useToast();

const options = {
    position: "bottom-right",
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
};

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

createInertiaApp({
    title: (title) => `${appName} | ${title}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(pinia)
            .use(Toast, options)
            .mixin({methods: {
                asset(path) {
                    var base_path = window._asset || '';
                    return base_path + path;
                }
            }});

        // Show flash errors
        if(props.initialPage.props.flash) {
            if(props.initialPage.props.flash.error) {
                setTimeout(() => {
                    toast.error(props.initialPage.props.flash.error);
                }, 200);
            }
        }

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
            integrations: [
                new Sentry.BrowserTracing(),
                new Sentry.Replay(),
            ],
            // Performance Monitoring
            tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
            // Session Replay
            replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAY_SESSION_RATE,
            replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAY_ON_ERROR_RATE,
        });

        app.config.globalProperties.$toast = toast;

        return app.mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
